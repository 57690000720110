import React from 'react';
import { Link } from 'gatsby';
import { trackEvent } from '@/utils/amplitude-tracking';

type ctaLinkProps = {
  to?: string;
  label: string;
  isExternal?: boolean;
  url?: string;
  bg?: string;
  className?: string;
};

const CtaLink = ({
  to,
  label,
  bg,
  className,
  url,
  isExternal,
}: ctaLinkProps) => {
  if (isExternal) {
    return (
      <a
        href={url}
        className={`box-border flex w-full rounded-[9px] bg-gradient-to-br from-orangey-yellow via-reddish-magenta to-purply-blue p-[1px] antialiased ${className}`}
      >
        <div
          className={`box-border flex w-full items-center justify-center rounded-[8px] px-8 py-[9px] text-sm font-light transition-colors duration-300 hover:bg-fuchsia-100 ${
            bg ? `${bg}` : 'bg-milky-white'
          }`}
        >
          {label}
        </div>
      </a>
    );
  }

  return (
    <Link
      onClick={() => trackEvent({ url: to })}
      to={`/${to}`}
      className={`box-border flex w-full rounded-[9px] bg-gradient-to-br from-orangey-yellow via-reddish-magenta to-purply-blue p-[1px] antialiased ${className}`}
    >
      <div
        className={`box-border flex w-full items-center justify-center rounded-[8px] px-8 py-[9px] text-sm font-light transition-colors duration-300 hover:bg-fuchsia-100 ${
          bg ? `${bg}` : 'bg-milky-white'
        }`}
      >
        {label}
      </div>
    </Link>
  );
};

export default CtaLink;
