import React from 'react';
import { PageProps } from 'gatsby';
import Layout from '@/components/common/layout';
import { useStaticQuery, graphql } from 'gatsby';
import Container from '@/components/common/container';
import Hero from '@/components/team/hero-v2';
import TeamMembers from '@/components/team/team-member';
import Benefits from '@/components/team/benefits';
import SEO from '@/components/seo';

const Team = ({ data }) => {
  const {
    seo,
    teamHeroSection,
    teamMembersSection,
    teamBenefitsSection,
    advisorsSection,
  } = data.sanityTeam;

  return (
    <Layout>
      <Hero
        title={teamHeroSection.title}
        subtitle={teamHeroSection.subtitle}
        images={teamHeroSection.images}
        ctaLink={teamHeroSection.ctaLink ? teamHeroSection.ctaLink : null}
      />
      <TeamMembers
        teamMembersTitle={teamMembersSection.title}
        teamMembers={teamMembersSection.teamMembers}
        ctaLink={teamHeroSection.ctaLink}
        advisors={advisorsSection.advisors}
        advisorsTitle={advisorsSection.title}
      />
      <Benefits
        title={teamBenefitsSection.title}
        subtitle={teamBenefitsSection.subtitle}
        benefits={teamBenefitsSection.benefits}
      />
    </Layout>
  );
};

export default Team;

export const query = graphql`
  query Team {
    sanityTeam {
      seo {
        metaTitle
        metaDescription
        image {
          asset {
            gatsbyImageData(width: 1280)
            url
            localFile(width: 1280) {
              publicURL
              childImageSharp {
                fixed(width: 1280) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
      }
      teamHeroSection {
        title
        subtitle
        images {
          asset {
            gatsbyImageData
          }
        }
        ctaLink {
          label
          slug {
            current
          }
        }
      }
      advisorsSection {
        title
        advisors {
          link
          name
          role
          country
          bio
          image {
            asset {
              gatsbyImageData
            }
          }
        }
      }
      teamMembersSection {
        title
        teamMembers {
          role
          name
          link
          country
          bio
          image {
            asset {
              gatsbyImageData
            }
          }
        }
      }
      teamBenefitsSection {
        title
        subtitle
        benefits {
          title
          description
          icon {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const { seo } = data.sanityTeam;

  return (
    <SEO
      title={seo.metaTitle}
      description={seo.metaDescription}
      image={seo && seo.image ? seo.image.asset.localFile.publicURL : null}
      imageWidth={
        seo && seo.image
          ? seo.image.asset.localFile.childImageSharp.fixed.width
          : null
      }
      imageHeight={
        seo && seo.image
          ? seo.image.asset.localFile.childImageSharp.fixed.height
          : null
      }
    />
  );
};
